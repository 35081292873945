import React, { useState } from "react"
import { graphql, navigate, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
// popup components
import PaymentBenefitsPopup from "../components/paymentBenefitsPopup"
import AppMethod from "../components/appMethod"
import PartnerPortal from "../components/partnerPortal"
import DealerResource from "../components/dealerResource"
import Training from "../components/training"
import LoanType from "../components/loanType"
import Support from "../components/support"
import GetPersonalizedService from "../components/getPersonalizedService"
import States from "../components/states"
import BenefitsLoanProgram from "../components/benefitsLoanProgram"
import GrowthCalc from "../components/growthCalc"
import ViewFile from "../components/viewFile"
import CustomizedPaymentOptionsProgram from "../components/customizePaymentOption"
import ToolsHelpYourProgram from "../components/toolsHelpYourProgram"
import CustomizedLaunchPlan from "../components/customizedLaunchPlan"
import WhatsInItForYou from "../components/whatsinitforyou"
import WhatsInItForYourDealers from "../components/whatsinitforyourdealers"
import expressSign from "../images/express-sign-combo.png"
import expressPay from "../images/express-pay-combo.png"
import maxApproval from "../images/max-approval-combo.png"
import useLiveControl from "../controlled/useLiveControl"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"


// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}


const LoanProgramPage = ({ data }) => {
  const node = data.wpgraphql.page

  const customLink = (url, e) => {
    navigate(url)
  }

  const [visible, setVisible] = useState(false)
  const [popType, setPopType] = useState("payment_options")
  const [popData, setPopData] = useState("")

  const showbenefitpopup = (param, type) => event => {
    event.preventDefault()
    setPopData(param)
    setVisible(true)
    setPopType(type)
  }

  

  const hidebenefitpopup = () => {
    setVisible(false)
  }

  const popup = () => {
    switch (popType) {
      case "payment_options":
        return (
          <PaymentBenefitsPopup
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "application-methods":
        return (
          <AppMethod
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "partner-portal":
        return (
          <PartnerPortal
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "dealer-resource-center":
        return (
          <DealerResource
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "training":
        return (
          <Training
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "loan-types":
        return (
          <LoanType
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "nationwide-presence":
        return (
          <States
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "unparalleled-support":
        return (
          <Support
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "get-personalized-service":
        return (
          <GetPersonalizedService
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "benefitsLoanProgram":
        return (
          <BenefitsLoanProgram
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "growthCalc":
        return (
          <GrowthCalc
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "viewFile":
        return (
          <ViewFile
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "customized-payment-options-program":
        return (
          <CustomizedPaymentOptionsProgram
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "customized-launch-plan-and-support":
        return (
          <CustomizedLaunchPlan
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "tools-help-your-program":
        return (
          <ToolsHelpYourProgram
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "whats-in-it-for-you":
        return (
          <WhatsInItForYou
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      case "whats-in-it-for-your-dealers":
        return (
          <WhatsInItForYourDealers
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      default:
        return ""
    }
  }

  const services = []
  const programMethod = []
  const onlineApp = []
  const rightChoice = []
  const createProgram = []

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Loan Programs for Contractors"}
        description={
          "EnerBank has developed specialized loan programs for contractors who want to grow their businesses. Create or join a loan program today."
        }
      />
      <Helmet>
        <body id={`${isStaging ? "rebrand" : null }`} className="loan-programs" />
      </Helmet>
      {isStaging ? (
        <section 
        className="banner-area"
        id="ultimate-guide-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "http://gbdevpress.enerbank.com/wp-content/uploads/2022/11/loan-programs-hero.jpg" +
                          ")",
                      }}
              >
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                      The right payment options program<br/> makes all the difference
                      </h1>
                    </div>
                  </div>
            </div>
            <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
            The right payment options program makes all the difference
            </h1>
          </div>
          </section>
        ) : (
       <div> 
      {node.video_section.video.videoUrl && (
        <section
          className="banner-area editable-page-hero-banner"
          id="loan-programs-page-banner-section"
          style={{
            backgroundImage:
              "url(" +
              "http://gbdevpress.enerbank.com/wp-content/uploads/2022/11/loan-programs-hero.jpg" +
              ")",
          }}
        >
          <div className="background-holder">
            {node.top_banner.banner.bannerLinks && (
              <div className="container d-flex flex-column flex-md-row align-items-end px-0 pb-3 pb-md-5 position-absolute banner-btn-container">
                {node.top_banner.banner.bannerLinks.map((item, i) => (
                  <div className="col-md-4" key={item.fieldGroupName + i}>
                    <div className="header-btn mx-md-3 mb-2 mb-md-0">
                      <Link
                        to={item.links.url}
                        dangerouslySetInnerHTML={{ __html: item.links.title }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      )}
      </div>
      )}
    <section className="service-area pt-5">
    {isStaging ? (
      <div className="container">
          <div className="col-md-12 mb-5">
            <h2 className="text-center">I'm interested in...</h2>
          </div>
          <div className="icon-cards row justify-content-center">
            <div className="col-lg-4">
              <Link className="card-link" to="/loan-programs/#paymentoptbenefits">
                <div className="card">         
                    <div className="card-image make-payment">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/hand-holding-star.png" className="" alt="Payment options benefits" />
                    </div>
                    <div className="card-body">
                        <h3>Payment Option Benefits <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link className="card-link" to="/loan-programs/#createloanprogram">
                <div className="card">
                    <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/create-loan-program-1.png" className="icon" alt="Create a loan program" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Create a Loan Program <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link className="card-link" to="/loan-programs/#joinloanprogram">
                <div className="card">
                    <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/join-loan-program.png" className="icon" alt="Join a loan program" />
                    </div>
                    <div className="card-body">
                        <h3>Join a Loan Program <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
          </div>
          <div className="row justify-content-center py-5">
              <div className="col-md-12 header-text">
                {/* <h2 className="text-center py-4 pb-5">The Right Payment Options Program Makes All the Difference</h2> */}
                <p className="has-normal-font-size py-2">
                  As a professional in the home improvement space, you rely on your partners to provide the best products and services for you and your customers. We&apos;ve developed specialized loan programs for contractors like you who want to grow your business, and industry organizations looking to overcome sales-related challenges.
                </p>
              </div>
            </div>
          <div className="row video-section">
          <div className="m-auto col-md-12">
            <Video
              videoSrcURL="https://www.youtube.com/embed/TKfYG6qFU44?rel=0"
              videoTitle="EnerBank Payment Options Overview"
              videoWidth="100%"
              videoHeight="500"
            />
          </div>
          <p className="text-center font-weight-bold mt-5 mb-4 mx-auto">
            Watch: Regions Home Improvment Financing Payment Options Overview
          </p>
        </div>
        <div className="row video-disclaimer">
          <div className="m-auto col-12 col-md-11">
            <p>
              <small>
                Regions provides links to YouTube and other websites
                merely and strictly for your convenience. The site is operated or controlled by a third party that is unaffiliated with Regions. The privacy policies and security at the linked website may differ from Regions' privacy and security policies and procedures. You should consult privacy disclosures at the linked website for further information.
              </small>
            </p>
          </div>
        </div>
       </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="mx-auto col-12 col-md-11 pt-4 py-md-5 mt-md-4 mb-md-3">
              <div
                className="payment-program"
                dangerouslySetInnerHTML={{ __html: node.content }}
              />
            </div>
          </div>
        
        <div className="row video-section">
          <div className="m-auto col-12 col-md-11 embed-container">
            <Video
              videoSrcURL="https://www.youtube.com/embed/TKfYG6qFU44?rel=0"
              videoTitle="EnerBank Payment Options Overview"
              videoWidth="100%"
              videoHeight="500"
            />
          </div>
          <p className="text-center font-weight-bold mt-5 mb-4 mx-auto">
            Watch: Regions | EnerBank USA Payment Options Overview
          </p>
        </div>
        <div className="row video-disclaimer">
          <div className="m-auto col-12 col-md-11">
            <p>
              <small>
                Regions | EnerBank provides links to YouTube and other websites
                merely and strictly for your convenience. The site is operated
                or controlled by a third party that is unaffiliated with
                EnerBank. The privacy policies and security at the linked
                website may differ from EnerBank's privacy and security policies
                and procedures. You should consult privacy disclosures at the
                linked website for further information.
              </small>
            </p>
          </div>
        </div>
        </div>
      )}
  </section>
  {isStaging ? (
    <section className="payment-option-benefits bg-light-gray">
      <div className="row">
        <div className="container">
          <div id="paymentoptbenefits" className="col-md-12">
            <h2 className="text-center">Payment options benefits</h2>
          </div>       
        </div>
      </div>
      <div className="container-lg">
        {node.loan_program.paymentOptionsBenefits.paymentOptionBrif && (
            <div className="row">
              <div className="mx-auto col-12 col-md-11 my-3 my-lg-5 ">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      node.loan_program.paymentOptionsBenefits.paymentOptionBrif,
                  }}
                />
              </div>
            </div>
          )}
        {node.loan_program.paymentOptionsBenefits.paymentOptions && (
          <div className="payment-option">
            <div className="icon-cards row justify-content-center">
              {node.loan_program.paymentOptionsBenefits.paymentOptions.map(
                (item, i) => 
                  (
                  
                  <div key={item.fieldGroupName + i} className="col-lg-2">
                    <Link
                      className="card-link"
                      to={"/"}
                      onClick={showbenefitpopup(item, "payment_options")}
                      data-title={item.paymentOptionTitle}
                      data-content="Advertising payment options is a great way to set you apart from your competitors and produce more leads for your business."
                    >
                      <div className="card">
                        <div className="card-image">
    
                            {/* if (i === 0) {
                              item.paymentOptionIcon.sourceUrl = "http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Improve-cash-flow_1.png"
                            } */}

                        {console.log("bnefit popup link: " + showbenefitpopup(item, 'payment_options') )}
                        {/* {(() =>
                           {for (var i = 0; i < item.paymentOptionIcon.sourceUrl.length; i++) {
                                if (item.paymentOptionIcon.sourceUrl === 'https://gbdevpress.enerbank.com/wp-content/uploads/2022/05/PaymentOptions_Improve_Cash_Flow_transparent.svg') {
                                 // {console.log("altText: " + item.paymentOptionIcon.altText )}
                                  return item.paymentOptionIcon.sourceUrl = 'http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Improve-cash-flow_1.png'
                                }
                              }}
                            )()} */}
                            <img
                                className="img-fluid"
                                src={item.paymentOptionIcon.sourceUrl}
                                alt={item.paymentOptionIcon.altText}
                              />
                        </div>
                        <div className="card-body">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.paymentOptionTitle,
                          }}
                        />
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {node.loan_program.offeringPaymentOption && (
          <div className="pt-5 mt-5 row icon-cards growth-cal">
            {node.loan_program.offeringPaymentOption.map(
              (item, i) =>
                i === 0 ? (
                  <div
                    key={item.fieldGroupName + i}
                    className="text-center col-12 col-md-4 p-0 mb-3 mx-auto"
                  >
                    <Link
                    className="card-link"
                      to={"/"}
                      onClick={showbenefitpopup(item, "growthCalc")}
                    >
                      <div className="card">
                      <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/calculator.png" />
                      </div>
                      <div
                        className="card-body"
                        dangerouslySetInnerHTML={{
                          __html: item.offeringPaymentBrif,
                        }}
                      />
                      </div>
                    </Link>
                  </div>
                ) : (
                  ""
                )
            )}
          </div>
        )}
      </div>
    </section>
    ) : (
      <section className="payment-option-benefits">
      {node.loan_program.paymentOptionsBenefits.paymentOptionHeading && (
        <div className="bg-blue mt-4 mt-md-4" id="paymentoptbenefits">
          <h2 className="text-center py-4 h1 font-weight-bold text-white">
            {node.loan_program.paymentOptionsBenefits.paymentOptionHeading}
          </h2>
        </div>
      )}
      <div className="container">
        {node.loan_program.paymentOptionsBenefits.paymentOptionBrif && (
          <div className="row">
            <div className="mx-auto col-12 col-md-11 my-3 my-lg-5 py-3">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    node.loan_program.paymentOptionsBenefits.paymentOptionBrif,
                }}
              />
            </div>
          </div>
        )}
        {node.loan_program.paymentOptionsBenefits.paymentOptions && (
          <div className="payment-option row">
            <ul className="col-12 px-3">
              {node.loan_program.paymentOptionsBenefits.paymentOptions.map(
                (item, i) => (
                  <li key={item.fieldGroupName + i}>
                    <Link
                      to={"/"}
                      onClick={showbenefitpopup(item, "payment_options")}
                      data-title={item.paymentOptionTitle}
                      data-content="Advertising payment options is a great way to set you apart from your competitors and produce more leads for your business."
                    >
                      <div className="box-circle icon regions-green content-center">
                        {(() =>
                          item?.paymentOptionIcon?.caption ? (
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={RemoveHTML(
                                item?.paymentOptionIcon?.caption
                              )}
                              preserveAspectRatio="none"
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              src={item.paymentOptionIcon.sourceUrl}
                              alt={item.paymentOptionIcon.altText}
                            />
                          ))()}
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.paymentOptionTitle,
                        }}
                      />
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        )}

        {node.loan_program.offeringPaymentOption && (
          <div className="my-3 my-md-5 py-4 row">
            {node.loan_program.offeringPaymentOption.map(
              (item, i) =>
                i === 0 ? (
                  <div
                    key={item.fieldGroupName + i}
                    className="box-shadow bg-white rounded text-center col-12 col-md-5 p-0 mb-3 mx-auto"
                  >
                    <Link
                      to={"/"}
                      onClick={showbenefitpopup(item, "growthCalc")}
                    >
                      <div className="bg-blue p-5 mx-3 mx-md-auto rounded-top">
                        <FontAwesomeIcon
                          className="fa-icon"
                          icon="calculator"
                          preserveAspectRatio="none"
                        />
                      </div>
                      <div
                        className="p-4 text-black"
                        dangerouslySetInnerHTML={{
                          __html: item.offeringPaymentBrif,
                        }}
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )
            )}
          </div>
        )}
        </div>
      </section>
    )}
     {isStaging ? (
      <>
        {node.loan_program.joinLoanProgram && (
        <section className="joinLoanProgram">

          {node.loan_program.joinLoanProgram.loanProgramMethodHeading && (
            <div className="" id="joinloanprogram">
              <h2 className="text-center ">
                Join a loan program
              </h2>
            </div>
          )}
          <div className="container">
            <div className="row pb-4">
              <p
                className="mx-auto col-12 col-md-11 my-5 pt-md-3 text-center"
                dangerouslySetInnerHTML={{
                  __html: node.loan_program.joinLoanProgram.loanProgramIntro,
                }}
              />
              <button  onClick={() => customLink("/sign-up/")} className="m-auto px-5 btn btn-info btn-green">
                Join Now
              </button>
            </div>
            <h3 className="h2 text-center f-bold border-heading my-5">
              <span>{node.loan_program.joinLoanProgram.sectionTitle}</span>
            </h3>
            {node.loan_program.joinLoanProgram.loanProgramMethods && (
              <div className="row mt-5 pt-5 justify-content-center multi-cards">
                {data.wpgraphql.popupplural.edges.forEach(cate => {
                  cate.node.slug === "loanprogrammethods" &&
                    cate.node.popupPost.edges.forEach(post => {
                      node.loan_program.joinLoanProgram.loanProgramMethods.map(
                        (item, i) =>
                          item.popSlug === post.node.slug &&
                          (programMethod[i] = post.node)
                      )
                    })
                })}

                {node.loan_program.joinLoanProgram.loanProgramMethods.map(
                  (item, i) => (
                    <div
                      className={`col-md-6 col-lg-4 mb-4 mb-lg-0 ${
                        item.popSlug === "dealer-resource-center"
                          ? "hide"
                          : "justify-content-center"
                      }`}
                      key={item.fieldGroupName + i}
                    >
                      <Link className="card-link"
                      to={"/"}
                      onClick={showbenefitpopup(
                                programMethod[i],
                                item.popSlug
                              )}
                      >
                        <div className="card loan-program-method">
                          <div className={"card-image " + item.popSlug}>
                            <img
                              src={item.programMethodsBgImage.sourceUrl}
                              className="card-img-top"
                              alt={item.programMethodsBgImage.altText}
                            />
                          </div>
                        <div className="card-body">
                          <h3
                            className="card-title"
                            dangerouslySetInnerHTML={{
                              __html: item.programMethodTitle,
                            }}
                          />
                          <p
                            className="card-text mb-0 mt-auto text-center"
                            dangerouslySetInnerHTML={{
                              __html: item.programMethodBrif,
                            }}
                          />
                        </div>  
                      </div>
                    </Link>
                  </div>
                  )
                )}
              </div>
            )}
          </div>
        </section>
      )}
      </>
     ) : (
      <>
      {node.loan_program.joinLoanProgram && (
        <section className="joinLoanProgram">
          {node.loan_program.joinLoanProgram.loanProgramMethodHeading && (
            <div className="bg-blue" id="joinloanprogram">
              <h3 className="text-center py-4 h1 f-bold text-white">
                {node.loan_program.joinLoanProgram.loanProgramMethodHeading}
              </h3>
            </div>
          )}
          <div className="container">
            <div className="row pb-4">
              <p
                className="mx-auto col-12 col-md-11 my-5 pt-md-3 font-weight-bold text-center"
                dangerouslySetInnerHTML={{
                  __html: node.loan_program.joinLoanProgram.loanProgramIntro,
                }}
              />
              <Link to={"/sign-up/"} className="m-auto px-5 btn btn-primary">
                Join Now
              </Link>
            </div>
            <h3 className="h2 text-center f-bold border-heading mt-5 mb-3">
              <span>{node.loan_program.joinLoanProgram.sectionTitle}</span>
            </h3>
            {node.loan_program.joinLoanProgram.loanProgramMethods && (
              <div className="row mt-4 mb-md-5 justify-content-center">
                {data.wpgraphql.popupplural.edges.forEach(cate => {
                  cate.node.slug === "loanprogrammethods" &&
                    cate.node.popupPost.edges.forEach(post => {
                      node.loan_program.joinLoanProgram.loanProgramMethods.map(
                        (item, i) =>
                          item.popSlug === post.node.slug &&
                          (programMethod[i] = post.node)
                      )
                    })
                })}

                {node.loan_program.joinLoanProgram.loanProgramMethods.map(
                  (item, i) => (
                    <div
                      className={`col-md-6 col-lg-4 mb-4 mb-lg-0 ${
                        item.popSlug === "dealer-resource-center"
                          ? "hide"
                          : "justify-content-center"
                      }`}
                      key={item.fieldGroupName + i}
                    >
                      <div className="card wygbox box-shadow loan-program-method">
                        <style
                          dangerouslySetInnerHTML={{
                            __html: [
                              "." + item.popSlug + ":after {",
                              "background: rgba(77, 77, 77, 0.3) url(" +
                                item.programMethodIcon.sourceUrl +
                                ") no-repeat center",
                              "}",
                            ].join("\n"),
                          }}
                        ></style>
                        <div className={"wygthumb " + item.popSlug}>
                          <img
                            src={item.programMethodsBgImage.sourceUrl}
                            className="card-img-top"
                            alt={item.programMethodsBgImage.altText}
                          />
                        </div>
                        <div className="card-body">
                          <h3
                            className="card-title"
                            dangerouslySetInnerHTML={{
                              __html: item.programMethodTitle,
                            }}
                          />
                          <p
                            className="card-text mb-3 mt-auto"
                            dangerouslySetInnerHTML={{
                              __html: item.programMethodBrif,
                            }}
                          />
                        </div>
                        {(() =>
                          item.popSlug === "dealer-resource-center" ? (
                            <button className="border-0 rounded-bottom bg-blue text-white text-lg py-3 btn-disabled">
                              Coming Soon
                            </button>
                          ) : (
                            <button
                              className="btn-link text-lg py-3"
                              onClick={showbenefitpopup(
                                programMethod[i],
                                item.popSlug
                              )}
                            >
                              Learn More
                            </button>
                          ))()}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </section>
      )}
      </>
    )}
      {isStaging ? (
      <section className="express-system bg-light-gray">
        <h2 className="text-center">
          <span>Regions Express System</span>
        </h2>
        <div className="container">
        <div className="express-intro">
          <p className="col-12 col-md-11 pt-5 text-center mb-5">
            The Express System puts time and money on your side with a suite of
            features that can help grow your business.
          </p>
        </div>
        <div className="row mt-5 multi-cards">
          <div className="col-4">
            <div className="card">
              <div className="card-image">
                {/* <img src={expressSign} className="img-fluid" alt="ExpressSign" /> */}
                <h2 className="text-center">ExpressSign</h2>
              </div>

              <div className="card-body">
                <h3 className="text-blue py-3 f-bold">Easy Signature<br/> Process</h3>
                <p>
                  A streamlined, signature process means you can close more deals,
                  and lets you focus on your business while creating an
                  exceptional customer experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-image">
                {/* <img src={expressPay} className="img-fluid" alt="ExpressPay" /> */}
                <h2 className="text-center">ExpressPay<sup>®</sup></h2>
              </div>

              <div className="card-body">
                <h3 className="text-blue py-3 f-bold">
                  Manage Cash Flow <br/>On Your Terms
                </h3>
                <p>
                  The flexibility of multiple disbursements allows you to get your
                  money when you need it, so you can keep your cash flow strong to
                  cover your material costs*
                </p>
                <em>*For eligible contractors</em>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-image">
                {/* <img src={maxApproval} className="img-fluid" alt="MaxApproval" /> */}
                <h2 className="text-center">MaxApproval</h2>
              </div>

              <div className="card-body">
                <h3 className="text-blue py-3 f-bold">
                  Increase Customer<br/> Purchasing Power
                </h3>
                <p>
                  Manage scope changes, project expansions, and upselling with
                  approvals beyond the initial job estimate. Easily pre-bid
                  qualify customers from your website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      ) : (
        <section className="container px-lg-3 pt-5">
        <h3 className="h2 text-center f-bold border-heading mb-3">
          <span>EnerBank USA Express System</span>
        </h3>
        <div className="express-intro">
          <p className="mx-auto col-12 col-md-11 pt-md-3 font-weight-bold text-center">
            The Express System puts time and money on your side with a suite of
            features that can help grow your business.
          </p>
        </div>
        <ul className="row mt-4">
          <li className="col-sm m-3 box-shadow bg-white rounded text-center py-4">
            <div>
              <img src={expressSign} className="img-fluid" alt="ExpressSign" />
            </div>

            <div>
              <h4 className="text-blue py-3 f-bold">Easy signature process</h4>
              <p>
                A streamlined, signature process means you can close more deals,
                and lets you focus on your business while creating an
                exceptional customer experience.
              </p>
            </div>
          </li>
          <li className="col-sm m-3 box-shadow bg-white rounded text-center py-4">
            <div>
              <img src={expressPay} className="img-fluid" alt="ExpressPay" />
            </div>

            <div>
              <h4 className="text-blue py-3 f-bold">
                Manage cash flow on your terms
              </h4>
              <p>
                The flexibility of multiple disbursements allows you to get your
                money when you need it, so you can keep your cash flow strong to
                cover your material costs*
              </p>
              <em>*For eligible contractors</em>
            </div>
          </li>
          <li className="col-sm m-3 box-shadow bg-white rounded text-center py-4">
            <div>
              <img src={maxApproval} className="img-fluid" alt="MaxApproval" />
            </div>

            <div>
              <h4 className="text-blue py-3 f-bold">
                Increase customer purchasing power
              </h4>
              <p>
                Manage scope changes, project expansions, and upselling with
                approvals beyond the initial job estimate. Easily pre-bid
                qualify customers from your website.
              </p>
            </div>
          </li>
        </ul>
      </section>
      )}
    <section className={`${isStaging ? null : "online-app-integration pb-5 mb-5" }`}>
      {isStaging ? (
        <>
      {node.loan_program.onlineAppIntegration && (
          <div className="container">
            {node.loan_program.onlineAppIntegration.appIntegrationBrif && (
              <div className="">
                <h2 className="text-center mb-5">
                  {node.loan_program.onlineAppIntegration.appIntegrationHeading}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      node.loan_program.onlineAppIntegration.appIntegrationBrif,
                  }}
                />
              </div>
            )}

            {node.loan_program.onlineAppIntegration.appIntegrationStep && (
              <div
                className="p-3 p-sm-4 p-lg-5 mb-5"
                style={{ backgroundColor: "#e6e7e8" }}
              >
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/08/online-app-int-6steps-rebrand.png"
                  alt={
                    node.loan_program.onlineAppIntegration.appIntegrationStep
                      .altText
                  }
                />
              </div>
            )}

            {node.loan_program.onlineAppIntegration.appIntegrationOption && (
              <div className="row pt-5 justify-content-center multi-cards">
                {data.wpgraphql.popupplural.edges.forEach(cate => {
                  cate.node.slug === "appintegrationoptions" &&
                    cate.node.popupPost.edges.forEach(post => {
                      node.loan_program.onlineAppIntegration.appIntegrationOption.map(
                        (item, i) =>
                          item.popSlug === post.node.slug &&
                          (onlineApp[i] = post.node)
                      )
                    })
                })}

                {node.loan_program.onlineAppIntegration.appIntegrationOption.map(
                  (item, i) => {
                    // const appIntOpt = ["loantype", "training"]
                    return (
                      <div className="col-5">
                        <Link className="card-link"
                        to={"/"}
                        onClick={showbenefitpopup(
                                  onlineApp[i],
                                  item.popSlug
                                )}
                        >
                          <div
                            key={item.fieldGroupName + i}
                            className={
                              "card "
                            }
                          >
                          <div className="card-image">
                            <img
                              src={item.integrationOptionBgImage.sourceUrl}
                              className="card-img-top"
                              alt={item.integrationOptionBgImage.altText}
                            />
                          </div>
                          <div className="card-body">
                            <h3
                              className="mb-1 flex-fill"
                              dangerouslySetInnerHTML={{
                                __html: item.integrationOptionHeading,
                              }}
                            />
                            <p
                              className="card-text mb-3 flex-fill"
                              dangerouslySetInnerHTML={{
                                __html: item.integrationOptionBrif,
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                    )
                  }
                )}
              </div>
            )}
          </div>

      )}
    </>
  ) : (
    <>
  {node.loan_program.onlineAppIntegration && (
        <>
          <div className="container pt-1">
            {node.loan_program.onlineAppIntegration.appIntegrationBrif && (
              <div className="mt-5 pt-4 pb-3 px-4 px-lg-5 box-shadow">
                <h3 className="h2 text-center text-blue f-bold mb-3">
                  {node.loan_program.onlineAppIntegration.appIntegrationHeading}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      node.loan_program.onlineAppIntegration.appIntegrationBrif,
                  }}
                />
              </div>
            )}

            {node.loan_program.onlineAppIntegration.appIntegrationStep && (
              
              <div
                className="p-3 p-sm-4 p-lg-5 mb-5"
                style={{ backgroundColor: "#e6e7e8" }}
              >
                <img
                  src={
                    node.loan_program.onlineAppIntegration.appIntegrationStep
                      .sourceUrl
                  }
                  alt={
                    node.loan_program.onlineAppIntegration.appIntegrationStep
                      .altText
                  }
                />
              </div>
            )}

            {node.loan_program.onlineAppIntegration.appIntegrationOption && (
              <div className="row pt-5">
                {data.wpgraphql.popupplural.edges.forEach(cate => {
                  cate.node.slug === "appintegrationoptions" &&
                    cate.node.popupPost.edges.forEach(post => {
                      node.loan_program.onlineAppIntegration.appIntegrationOption.map(
                        (item, i) =>
                          item.popSlug === post.node.slug &&
                          (onlineApp[i] = post.node)
                      )
                    })
                })}

                {node.loan_program.onlineAppIntegration.appIntegrationOption.map(
                  (item, i) => {
                    const appIntOpt = ["loantype", "training"]
                    return (
                      <div
                        key={item.fieldGroupName + i}
                        className={
                          "card box-shadow bg-white rounded text-center payment-option-box col-12 col-md-6 mb-3 mb-lg-0 p-0 ml-md-3 ml-lg-5 " +
                          appIntOpt[i]
                        }
                      >
                        <div className="wygthumb">
                          <img
                            src={item.integrationOptionBgImage.sourceUrl}
                            className="card-img-top"
                            alt={item.integrationOptionBgImage.altText}
                          />
                        </div>
                        <div className="p-4 card-body">
                          <h3
                            className="mb-1 flex-fill"
                            dangerouslySetInnerHTML={{
                              __html: item.integrationOptionHeading,
                            }}
                          />
                          <p
                            className="card-text mb-3 flex-fill"
                            dangerouslySetInnerHTML={{
                              __html: item.integrationOptionBrif,
                            }}
                          />
                          <button
                            className="btn-link text-lg mt-auto"
                            onClick={showbenefitpopup(
                              onlineApp[i],
                              item.popSlug
                            )}
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            )}
          </div>
        </>
      )}
      </>

    )}
  </section>
  <section className="program-services bg-light-gray">
  {isStaging ? (
      <>
      {node.loan_program && (
        <div className="container">
          <div className="row">
            <div className="mx-auto col-12 col-md-11">
              <div className="payment-program">
                <h2 className="text-center mb-5">
                  What&#39;s your specialty?<br/> We&#39;ve got loans for almost any project!
                </h2>
                <p>
                  We offer loans for a ton of different home improvement project
                  types. From the foundation all the way up to the roof —
                  inside, outside, large or small — our loans are tailored to
                  meet virtually any home improvement need. Contact us to
                  discover which loans will best suit your business model.
                </p>
              </div>
              <div className="text-center pb-5">
              <button  onClick={() => customLink("/sign-up/")} className="m-auto px-5 btn btn-info btn-green">
                Contact Us
              </button>
              </div>
              <div className="py-md-5 pb-0 payment-program">
                <h3 className="h2 text-center mb-3">
                  Our Contractor Support is Here for You
                </h3>
                <p>
                  We work closely with home improvement contractors across the
                  nation. Because we focus on home improvement loans, we can
                  provide specialized and unparalleled service, starting with
                  your dedicated relationship manager.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center icon-cards pt-4">
            {data.wpgraphql.popupplural.edges.map(cate => {
              return (
                cate.node.slug === "loanservices" &&
                cate.node.popupPost.edges.forEach(post => {
                  node.loan_program.loanServices.map(
                    (item, i) =>
                      item.popSlug === post.node.slug &&
                      (services[i] = post.node)
                  )
                })
              )
            })}
            {node.loan_program.loanServices.map((item, i) => {
              return (
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                  key={item.fieldGroupName + i}
                >
                <Link className="card-link"
                    to={"/"}
                    onClick={showbenefitpopup(services[i], item.popSlug)}
                  >
                  <div className="card">
                    <div className="card-image">
                      {(() =>
                        item?.serviceIcon?.caption ? (
                          <FontAwesomeIcon
                            className="fa-icon"
                            icon={RemoveHTML(item?.serviceIcon?.caption)}
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={item.serviceIcon.sourceUrl}
                            alt={item.serviceIcon.altText}
                          />
                        ))()}
                    </div>
                    <div className="card-body">
                      <h3 className="mb-3">{item.serviceHeading}</h3>
                      <p className="text-center">{item.serviceText}</p>
                    </div>
                    
                  </div>
                </Link>
              </div>
              )
            })}
          </div>
        </div>
      )}
      </>
  ) : (
    <>
      {node.loan_program && (
        <div className="container">
          <div className="row">
            <div className="mx-auto col-12 col-md-11">
              <div className="mt-5 py-md-4 pb-0 payment-program">
                <h3 className="h2 text-center font-weight-bold mb-3">
                  What&#39;s Your Specialty? We&#39;ve Got Loans for Almost Any Project!
                </h3>
                <p>
                  We offer loans for a ton of different home improvement project
                  types. From the foundation all the way up to the roof —
                  inside, outside, large or small — our loans are tailored to
                  meet virtually any home improvement need. Contact us to
                  discover which loans will best suit your business model.
                </p>
              </div>
              <div className="text-center pb-5">
                <a
                  className="m-auto px-5 btn btn-primary font-weight-bold"
                  href="/sign-up/"
                >
                  Contact Us
                </a>
              </div>
              <div className="py-md-5 pb-0 payment-program">
                <h3 className="h2 text-center font-weight-bold mb-3">
                  Our Contractor Support is Here for You
                </h3>
                <p>
                  We work closely with home improvement contractors across the
                  nation. Because we focus on home improvement loans, we can
                  provide specialized and unparalleled service, starting with
                  your dedicated relationship manager.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center pt-4">
            {data.wpgraphql.popupplural.edges.map(cate => {
              return (
                cate.node.slug === "loanservices" &&
                cate.node.popupPost.edges.forEach(post => {
                  node.loan_program.loanServices.map(
                    (item, i) =>
                      item.popSlug === post.node.slug &&
                      (services[i] = post.node)
                  )
                })
              )
            })}
            {node.loan_program.loanServices.map((item, i) => {
              return (
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                  key={item.fieldGroupName + i}
                >
                  <div className="single-offer card-body rounded">
                    <i className="icon_circle">
                      {(() =>
                        item?.serviceIcon?.caption ? (
                          <FontAwesomeIcon
                            className="fa-icon"
                            icon={RemoveHTML(item?.serviceIcon?.caption)}
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={item.serviceIcon.sourceUrl}
                            alt={item.serviceIcon.altText}
                          />
                        ))()}
                    </i>
                    <h4>{item.serviceHeading}</h4>
                    <p className="flex-fill">{item.serviceText}</p>
                    <Link
                      to={"/"}
                      onClick={showbenefitpopup(services[i], item.popSlug)}
                      className="btn btn-primary f-bold"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="p-4 eb-difference">
            <h3 className="h2 text-center font-weight-bold">
              Essential Questions for Contractors to Ask
            </h3>
            <p className="text-center py-2">
              {node.loan_program.loanProvider.sectionBrif}
            </p>
            <div className="d-flex justify-content-center howselectloan">
              <div className="text-center pb-5 header-btn">
                <a
                  className="m-auto px-5 btn btn-primary font-weight-bold"
                  href="/regions-enerbank-difference/"
                >
                  The Regions | EnerBank Difference
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      </>
  )}
</section>
      <section id="faqs" className={`faq-section ${isStaging ? null : "bg-light-gray" }`}>
      {isStaging ? (
        <>
          <div className="container">
            <h2 className="text-center mb-5">
              Frequently asked questions
            </h2>
          </div>
        <div className="container">
        <div className="row">
                <div className="col-md-12">
                    <Tabbordion
                        blockElements={blockElements}
                        animateContent={"height"}
                        mode={"toggle"}
                        className="regions-accordion"
                        name="accordion" >
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Do Regions Home Improvement Financing loan programs include pre-approval options?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Yes! Your customers can now start the application process by utilizing PreQualify. With this method, both you and the homeowner can get a better idea of what they can afford with no hit to their credit<sup>*</sup>. This allows you to sell on value rather than price, and better recommend products and manage scope.</p>
                                <p><small><sup>*</sup>A hard credit check will take place if the homeowner applies for a loan</small></p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">How fast can a homeowner be approved for financing?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>          
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>It depends on the homeowner. Often, they can receive a credit decision within minutes, allowing you to have the entire process completed while you're in the home. Sometimes, however, more information is needed to complete the application, and this may cause delays. If a customer has a question about their credit decision, they can call our Customer Service team at <a href="tel:1.888.390.1220">1.888.390.1220</a>.
                                </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Should I join a loan program or create a loan program?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Joining a loan program is one of the easiest ways that a contractor can begin offering financing to their customers and is the best fit for smaller home improvement businesses. If your organization works with a network of contractors, installers, dealers, or other authorized clients who work in the home improvement business, creating a loan program is likely the best fit for you.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">What costs are associated with Regions Home improvement Financing?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>
                                There are no fixed or required costs to work with Regions. Many of our most popular loan products have a dealer fee. Dealer fees are clearly communicated so you know exactly what they will be each time, and there are no hidden or surprise fees. We don't charge merchant processing fees or program membership fees, and there are no hidden fees for your customers..</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Why would contractor financing be better than other options, like credit cards?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Research has shown that your business can experience many benefits from offering financing that you won't see with other payment methods. In a study<sup>*</sup> commissioned by Regions, contractors who offered every customer a choice of payment options saw an increase in leads, more closed jobs, larger average project sizes, and more. Plus, we handle the heavy lifting of what a financing program entails so you can focus your attention elsewhere.</p>
                                <p><small><sup>*</sup>Based on the Brickyard Study, commissioned by Regions 2018.</small></p>
                            </TabContent>
                        </TabPanel>
                    </Tabbordion>
                </div>
             </div>
        </div>
        </>
      ) : (
        <>
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="py-5">
            <dl>
              <div
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemProp="name">
                    Do EnerBank loan programs include pre-approval options?
                  </span>
                </dt>
                <dd
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span className="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemProp="text">
                    Yes! Your customers can now start the application process by
                    utilizing PreQualify. With this method, both you and the
                    homeowner can get a better idea of what they can afford with
                    no hit to their credit<sup>*</sup>. This allows you to sell
                    on value rather than price, and better recommend products
                    and manage scope.
                    <br />
                    <small>
                      <sup>*</sup>A hard credit check will take place if the
                      homeowner applies for a loan
                    </small>
                  </span>
                </dd>
              </div>

              <div
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemProp="name">
                    How fast can a homeowner be approved for financing?
                  </span>
                </dt>
                <dd
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span className="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemProp="text">
                    It depends on the homeowner. Often, they can receive a
                    credit decision within minutes, allowing you to have the
                    entire process completed while you&#39;re in the home.
                    Sometimes, however, more information is needed to complete
                    the application, and this may cause delays. If a customer
                    has a question about their credit decision, they can call
                    our Customer Service team at{" "}
                    <a href="tel:1.888.390.1220">1.888.390.1220</a>.
                  </span>
                </dd>
              </div>

              <div
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemProp="name">
                    Should I join a loan program or create a loan program?
                  </span>
                </dt>
                <dd
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span className="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemProp="text">
                    Joining a loan program is one of the easiest ways that a
                    contractor can begin offering financing to their customers
                    and is the best fit for smaller home improvement businesses.
                    If your organization works with a network of contractors,
                    installers, dealers, or other authorized clients who work in
                    the home improvement business, creating a loan program is
                    likely the best fit for you.
                  </span>
                </dd>
              </div>

              <div
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemProp="name">
                    What costs are associated with EnerBank financing?{" "}
                  </span>
                </dt>
                <dd
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span className="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemProp="text">
                    There are no fixed or required costs to work with EnerBank.
                    Many of our most popular loan products have a dealer fee.
                    Dealer fees are clearly communicated so you know exactly
                    what they will be each time, and there are no hidden or
                    surprise fees. We don&#39;t charge merchant processing fees
                    or program membership fees, and there are no hidden fees for
                    your customers.
                  </span>
                </dd>
              </div>

              <div
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemProp="name">
                    Why would contractor financing be better than other options,
                    like credit cards?{" "}
                  </span>
                </dt>
                <dd
                  itemScope
                  itemProp="acceptedAnswer"
                  itemType="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span className="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemProp="text">
                    Research has shown that your business can experience many
                    benefits from offering financing that you won&#39;t see with
                    other payment methods. In a study<sup>*</sup> commissioned
                    by EnerBank, it showed that contractors who offered every
                    customer a choice of payment options saw an increase in
                    leads, more closed jobs, larger average project sizes, and
                    more. Plus, we handle the heavy lifting of what a financing
                    program entails so you can focus your attention elsewhere.
                    <br />
                    <small>
                      <sup>*</sup>The Brickyard Study, commissioned by EnerBank
                      USA 2018
                    </small>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        </>
      )}
      </section>
      {isStaging ? (
      <section className="create-loan-program bg-light-gray pb-0">
        <div id="createloanprogram">
          <h2 className="text-center mb-5">Create a loan program</h2>
        </div>
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-6 payment-program pb-5">
              <h3 className="text-center mb-3">
                What&#39;s a Loan Program?
              </h3>
              <p className="text-center">
                At its core, a payment options program is a way for you and your
                authorized dealers to achieve sales goals and overcome sales-related
                challenges. Your customized, private-label loan program is designed
                to achieve your organization&#39;s goals while building brand
                recognition.
              </p>
            </div>

            <div className="col-6 payment-program pb-5">
              <h3 className="text-center mb-3">
                Who Should Create a Loan Program?
              </h3>
              <p className="text-center">
                If your organization works with a network of contractors,
                installers, dealers, or other authorized clients who work in the
                home improvement business, you should create a loan program.
                It&#39;s a smart and easy way to boost your growth and gain brand
                loyalty.
              </p>
            </div>
          </div>
          <div className="row flex-wrap my-4 my-lg-5 multi-cards justify-content-center">
            <div className="col-12">
              <h3 className="h2 text-center mb-5">
                Why Should You Create a Loan Program?
              </h3>
            </div>
            {data.wpgraphql.popupplural.edges.forEach(cate => {
              cate.node.slug === "createloanprogram" &&
                cate.node.popupPost.edges.forEach(post => {
                  node.loan_program.createLoanOption.sectionOption.forEach(
                    (item, i) => {
                      item.popSlug === post.node.slug &&
                        (createProgram[i] = post.node)
                    }
                  )
                })
            })}
            {node.loan_program.createLoanOption.sectionOption.map((item, i) => {
              const appIntOpt = ["whatsinit", "whatsinitdealers"]
              return (
                <div
                  key={item.fieldGroupName + i}
                  className={"col-5"}
                >
                  <Link className="card-link"
                    to={"/"}
                    onClick={showbenefitpopup(createProgram[i], item.popSlug)}
                  >
                    <div className="card">
                      <div className="card-image benefitloan">
                        <img
                          src={item.integrationOptionBgImage.sourceUrl}
                          className="card-img-top"
                          alt={item.integrationOptionBgImage.altText}
                        />
                      </div>
                      <div className="card-body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.integrationOptionBrif,
                          }}
                        >
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>        
        </div>
        <h3 className="h2 text-center px-3 p-md-5 mt-4 mb-2 font-weight-bold">
          Why Regions is the Right Choice as Your Loan Program Provider
        </h3>
        <div className="container">
          <div className="row justify-content-center right-choice-program icon-cards">
            {data.wpgraphql.popupplural.edges.forEach(cate => {
              cate.node.slug === "rightchoiceloanprogram" &&
                cate.node.popupPost.edges.forEach(post => {
                  node.loan_program.rightChoice.forEach((item, i) => {
                    item.popSlug === post.node.slug &&
                      (rightChoice[i] = post.node)
                  })
                })
            })}

            {node.loan_program.rightChoice.map((item, i) => {
              return (
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                  key={item.fieldGroupName + i}
                >
                  <Link className="card-link"
                      to={"/"}
                      onClick={showbenefitpopup(rightChoice[i], item.popSlug)}
                    >
                      <div className="card">
                        <div className="card-image">
                          {(() =>
                            item.rightChoiceIcon.caption ? (
                              <FontAwesomeIcon
                                className="fa-icon"
                                icon={RemoveHTML(item.rightChoiceIcon.caption)}
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                src={item.rightChoiceIcon.sourceUrl}
                                alt={item.rightChoiceIcon.altText}
                              />
                            ))()}
                        </div>
                        <div
                          className="card-body"
                          dangerouslySetInnerHTML={{ __html: item.rightChoiceDesc }}
                        />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>

        <div className="easyApp marquee-teal">
          <div className="container">
            <div className="row justify-content-center align-items-center pb-5">
              <div className="col-12">
                  <h2 className="text-white text-center">Easy application and funding process</h2>
              </div>
            </div>
              <div className="row justify-content-center align-items-center">
              <div className="col-5">
                <img className="image" src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/superior-technology.jpg" />
              </div>
              <div className="col-6">
                
                <ul className="fa-ul">
                  {node.loan_program.easyApplication.easyApplicationProcess.map(
                    (item, i) => {
                      return (
                        <li key={item.fieldGroupName + i} className="text-white">
                          <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>{item.easyApplicationSteps}
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      ) : (
        <>
      <div className="bg-blue" id="createloanprogram">
        <h3 className="text-center py-4 h1 f-bold text-white">
          {node.loan_program.createLoanOption.sectionHeading}
        </h3>
      </div>

      <div className="container">
        <div className="py-5 px-md-5 pb-0 payment-program">
          <h3 className="h2 text-center mb-3 font-weight-bold">
            What&#39;s a Loan Program?
          </h3>
          <p>
            At its core, a payment options program is a way for you and your
            authorized dealers to achieve sales goals and overcome sales-related
            challenges. Your customized, private-label loan program is designed
            to achieve your organization&#39;s goals while building brand
            recognition.
          </p>
        </div>

        <div className="p-md-5 pb-0 mb-5 payment-program">
          <h3 className="h2 text-center mb-3 font-weight-bold">
            Who Should Create a Loan Program?
          </h3>
          <p>
            If your organization works with a network of contractors,
            installers, dealers, or other authorized clients who work in the
            home improvement business, you should create a loan program.
            It&#39;s a smart and easy way to boost your growth and gain brand
            loyalty.
          </p>
        </div>

        <h3 className="h2 text-center mb-4 mb-md-3 font-weight-bold">
          Why Should You Create a Loan Program?
        </h3>
        <div className="row flex-wrap my-4 my-lg-5">
          {data.wpgraphql.popupplural.edges.forEach(cate => {
            cate.node.slug === "createloanprogram" &&
              cate.node.popupPost.edges.forEach(post => {
                node.loan_program.createLoanOption.sectionOption.forEach(
                  (item, i) => {
                    item.popSlug === post.node.slug &&
                      (createProgram[i] = post.node)
                  }
                )
              })
          })}
          {node.loan_program.createLoanOption.sectionOption.map((item, i) => {
            const appIntOpt = ["whatsinit", "whatsinitdealers"]
            return (
              <div
                key={item.fieldGroupName + i}
                className={
                  "box-shadow bg-white rounded text-center payment-option-box col-12 col-md-12 col-md mb-3 mb-lg-0 p-0 mr-md-3 mr-lg-5 " +
                  appIntOpt[i]
                }
              >
                <div className="wygthumb benefitloan">
                  <img
                    src={item.integrationOptionBgImage.sourceUrl}
                    className="card-img-top"
                    alt={item.integrationOptionBgImage.altText}
                  />
                </div>
                <div className="p-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.integrationOptionBrif,
                    }}
                  ></div>
                  <button
                    onClick={showbenefitpopup(createProgram[i], item.popSlug)}
                    className="btn-link text-lg"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <h3 className="h2 text-center px-3 p-md-5 mt-4 mb-2 font-weight-bold">
        Why EnerBank is the Right Choice as Your Loan Program Provider
      </h3>

      <div className="container">
        <div className="row justify-content-center">
          {data.wpgraphql.popupplural.edges.forEach(cate => {
            cate.node.slug === "rightchoiceloanprogram" &&
              cate.node.popupPost.edges.forEach(post => {
                node.loan_program.rightChoice.forEach((item, i) => {
                  item.popSlug === post.node.slug &&
                    (rightChoice[i] = post.node)
                })
              })
          })}

          {node.loan_program.rightChoice.map((item, i) => {
            return (
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                key={item.fieldGroupName + i}
              >
                <div className="single-offer card-body">
                  <i className="icon_circle">
                    {(() =>
                      item.rightChoiceIcon.caption ? (
                        <FontAwesomeIcon
                          className="fa-icon"
                          icon={RemoveHTML(item.rightChoiceIcon.caption)}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={item.rightChoiceIcon.sourceUrl}
                          alt={item.rightChoiceIcon.altText}
                        />
                      ))()}
                  </i>
                  <div
                    className="py-4 flex-fill"
                    dangerouslySetInnerHTML={{ __html: item.rightChoiceDesc }}
                  />
                  <button
                    onClick={showbenefitpopup(rightChoice[i], item.popSlug)}
                    className="btn btn-primary f-bold"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="easyApp bg-blue my-5 py-5">
        <style
          dangerouslySetInnerHTML={{
            __html: [
              ".easyApp:after {",
              "background: #0077C8 url(" +
                node.loan_program.easyApplication.easyApplicationBanner
                  .sourceUrl +
                ") no-repeat left/cover",
              "}",
            ].join("\n"),
          }}
        ></style>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-5"></div>
            <div className="col-12 col-sm-7">
              <h3 className="text-white mb-4">
                {node.loan_program.easyApplication.easyApplicationHeading}
              </h3>
              <ul>
                {node.loan_program.easyApplication.easyApplicationProcess.map(
                  (item, i) => {
                    return (
                      <li key={item.fieldGroupName + i}>
                        {item.easyApplicationSteps} 
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
      
      {/* <div className="m-md-4 m-lg-5">
        <h3 className="h2 px-3 f-bold text-center font-weight-bold">
          {node.loan_program.selectPartner.sectionHeading}
        </h3>
        <p className="text-center py-2">
          {node.loan_program.selectPartner.sectionBrif}
        </p>
        <div className="d-flex justify-content-center howselectloan">
          {data.wpgraphql.popupplural.edges.map(cate => {
            cate.node.slug === "selectloanprogram" &&
              cate.node.popupPost.edges.map(post => {
                post.node.slug === "selecting-a-loan-program-partner" &&
                  (viewLoanPartnerPDF = post.node)
              })
          })}
          <button
            className="btn-link"
            onClick={showbenefitpopup(viewLoanPartnerPDF, "viewFile")}
          >
            VIEW
          </button>
          <div
            className="display-inline-block box-20"
            dangerouslySetInnerHTML={{
              __html: node.loan_program.selectPartner.yearBlock,
            }}
          />
          <button className="pdffile" onClick={() => DownloadFile(partnerPDF)}>
            <img src={pdffile} alt="download PDF file icon" /> PDF
          </button>
        </div>
      </div> */}
  {isStaging ? (
    <section className="bg-light-gray">
      {node.loan_program.partnerSays && (
        <>
          <div className="container pb-5">
            <h2 className="text-center mb-3">See what our customers say</h2>
          </div>

          <Carousel showArrows={true} centerMode infiniteLoop>
            {node.loan_program.partnerSays.map((item, i) => (
              <div key={item.fieldGroupName + i}>
                <img
                  src={item.partnerLogo.sourceUrl}
                  alt={item.partnerLogo.altText}
                  className="img-fluid mb-4"
                />
                <div
                  dangerouslySetInnerHTML={{ __html: item.partnerComment }}
                />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </section>
    ) : (
      <>
      {node.loan_program.partnerSays && (
        <>
          <div className="container">
            <h3 className="h2 text-center mx-md-5 mb-3 my-5 my-md-2 p-md-5 font-weight-bold">
              <span>{node.loan_program.testimonialHeading}</span>
              <hr
                style={{
                  height: "2px",
                  background: "#444",
                  marginTop: "1.5rem",
                }}
              ></hr>
            </h3>
          </div>

          <Carousel showArrows={true} centerMode infiniteLoop>
            {node.loan_program.partnerSays.map((item, i) => (
              <div key={item.fieldGroupName + i}>
                <img
                  src={item.partnerLogo.sourceUrl}
                  alt={item.partnerLogo.altText}
                  className="img-fluid mb-4"
                />
                <div
                  dangerouslySetInnerHTML={{ __html: item.partnerComment }}
                />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </>
    )}
    {isStaging ? (
        null
     ) : (  null )}
      {popup()}
    </Layout>
  )
}

export default LoanProgramPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      popupplural {
        edges {
          node {
            id
            slug
            popupPost {
              edges {
                node {
                  id
                  slug
                  title
                  content
                  appmethod {
                    paperlessmethod {
                      fieldGroupName
                      sectionicon {
                        sourceUrl
                        altText
                        caption
                      }
                      sectiontitle
                      sectionContent
                    }
                  }
                  dealerresourcecenter {
                    drcnow {
                      sectiondesc
                      sectionheading
                      sectionlink {
                        target
                        title
                        url
                      }
                    }
                    powerfulltool {
                      fieldGroupName
                      sectioncontent
                      sectiontitle
                      sectionicon {
                        sourceUrl
                      }
                      sectionlink {
                        target
                        title
                        url
                      }
                    }
                  }
                  innerpagebanner {
                    pagebanner {
                      bannericon {
                        sourceUrl
                      }
                      bannner {
                        sourceUrl
                        altText
                      }
                      bannertext
                    }
                  }
                  partnerportal {
                    portalservice {
                      accessportallink {
                        target
                        title
                        url
                      }
                      servicegroup {
                        fieldGroupName
                        servicetitle
                      }
                    }
                  }
                  popupchecklist {
                    fieldGroupName
                    checklistGroup {
                      checklistName
                      fieldGroupName
                    }
                  }
                  accordion {
                    fieldGroupName
                    tabpanel {
                      fieldGroupName
                      tabcontent
                      tablabel
                    }
                  }
                  bannerrepeater {
                    bannerGroup {
                      fieldGroupName
                      banner {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        top_banner {
          banner {
            backgroundImage {
              sourceUrl
            }
            bannerLinks {
              fieldGroupName
              links {
                title
                url
              }
            }
          }
        }

        loan_program {
          testimonialHeading
          partnerSays {
            fieldGroupName
            partnerComment
            partnerLogo {
              sourceUrl
              altText
            }
          }
          paymentOptionsBenefits {
            paymentOptionHeading
            paymentOptionBrif
            paymentOptions {
              fieldGroupName
              paymentOptionTitle
              paymentOptionPopupTitle
              paymentOptionPopupContent
              paymentOptionIcon {
                sourceUrl
                altText
                title
                caption
              }
            }
          }
          offeringPaymentOption {
            fieldGroupName
            offeringPaymentBrif
            offeringPaymentIcon {
              sourceUrl
            }
          }
          joinLoanProgram {
            loanProgramMethodHeading
            loanProgramIntro
            sectionTitle
            loanProgramMethods {
              fieldGroupName
              popSlug
              programMethodBrif
              programMethodIcon {
                sourceUrl
              }
              programMethodsBgImage {
                sourceUrl
                altText
              }
              programMethodTitle
              programMethodLink {
                target
                title
                url
              }
            }
          }
          onlineAppIntegration {
            appIntegrationBrif
            appIntegrationHeading
            appIntegrationOption {
              fieldGroupName
              popSlug
              integrationOptionBrif
              integrationOptionBgImage {
                altText
                sourceUrl
              }
              integrationOptionIcon {
                sourceUrl
              }
              integrationOptionHeading
              integrationOptionPopslug
            }
            appIntegrationStep {
              sourceUrl
            }
          }

          loanServices {
            fieldGroupName
            serviceHeading
            serviceText
            popSlug
            serviceLink {
              target
              title
              url
            }
            serviceIcon {
              sourceUrl
              altText
              caption
            }
          }

          loanProvider {
            fieldGroupName
            sectionHeading
            sectionBrif
            yearBlock
            downloadDoc {
              altText
              mediaItemUrl
              mediaType
            }
          }

          selectPartner {
            fieldGroupName
            sectionBrif
            sectionHeading
            yearBlock
            downloadDoc {
              altText
              mediaItemUrl
              mediaType
            }
          }

          createLoanOption {
            sectionHeading
            sectionBrif
            sectionOption {
              fieldGroupName
              popSlug
              integrationOptionBrif
              integrationOptionBgImage {
                altText
                sourceUrl
              }
              integrationOptionIcon {
                sourceUrl
                altText
              }
            }
          }
          rightChoice {
            fieldGroupName
            rightChoiceDesc
            rightChoiceIcon {
              sourceUrl
              altText
              caption
            }
            rightChoiceLink {
              target
              title
              url
            }
            popSlug
          }
          easyApplication {
            easyApplicationHeading
            fieldGroupName
            easyApplicationBanner {
              sourceUrl
              altText
            }
            easyApplicationProcess {
              easyApplicationSteps
              fieldGroupName
            }
          }
        }
        accordion {
          tabpanel {
            fieldGroupName
            tabcontent
            tablabel
          }
        }

        video_section {
          video {
            videoUrl
            videoBanner {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
